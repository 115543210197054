interface ToggleProps {
  onClick: () => void;
  value: boolean;
  disabled?: boolean;
}

export const Toggle = ({ onClick, value, disabled = false }: ToggleProps) => (
  <div onClick={onClick}>
    <div
      className={`relative h-6 w-12 rounded-xl ${
        value ? (disabled ? 'bg-turquoise-200' : 'bg-turquoise-500') : 'bg-gray-500'
      } ${disabled && 'cursor-not-allowed'}`}
    >
      <span
        className={`absolute block size-[20px] rounded-full bg-white transition-transform ${
          value ? 'translate-x-[26px] translate-y-[2px]' : 'translate-x-[2px] translate-y-[2px]'
        }`}
      ></span>
    </div>
  </div>
);
